import { OutboundLink } from "gatsby-plugin-google-gtag";
import React from "react";
import tw from "twin.macro";
import {
  OpenCorrectZorbiLink,
  useCanUseExtension,
  useDetectBrowser,
} from "../../utils";

const CTAButton = tw(
  OutboundLink
)`inline-block px-8 py-4 text-xs font-semibold leading-none text-purple-600 transition duration-300 bg-white border-solid  hover:bg-purple-600 hover:text-white hover:border-white rounded-DEFAULT`;

export const CustomGetStarted: React.FC<{
  href: string;
  linkText: string;
  headerText: string;
  onClick?: () => void;
}> = ({ href, linkText, headerText, onClick }) => {
  return (
    <section className="pt-20 pb-24 bg-purple-600">
      <div className="max-w-xl px-4 mx-auto text-center">
        <h2 className="mt-3 text-3xl font-bold text-white md:text-4xl font-heading">
          {headerText}
        </h2>
        <div className="mt-6">
          <CTAButton
            href={href}
            onClick={(e) => {
              e.preventDefault();
              onClick?.();
            }}
          >
            {linkText}
          </CTAButton>
        </div>
      </div>
    </section>
  );
};

export const GetStarted = () => {
  const browser = useDetectBrowser();
  const canUseExtension = useCanUseExtension();

  return (
    <CustomGetStarted
      href="#"
      onClick={OpenCorrectZorbiLink}
      headerText="Get the Zorbi Extension now."
      linkText={
        canUseExtension
          ? browser && browser.name.includes("edge")
            ? "Add to Edge"
            : "Add to Chrome"
          : "Try Zorbi"
      }
    />
  );
};
