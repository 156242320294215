import React from "react";
import ReadAFlashcardImg from "../../images/how-it-works/read-a-flashcard.png";
import RateItsDifficultyImg from "../../images/how-it-works/rate-its-difficulty.png";
import ComeBackDailyImg from "../../images/how-it-works/come-back-daily.png";
import AceYourExamsImg from "../../images/how-it-works/ace-your-exams-2.svg";

interface TimelineItemProps {
  headerText: string;
  paragraphText: string;
  imgSrc: string;
  fillImg?: boolean;
}

const timelineItems: TimelineItemProps[] = [
  {
    headerText: "Read a question",
    paragraphText: "Strengthen your memory recall by answering questions",
    imgSrc: ReadAFlashcardImg,
  },
  {
    headerText: "Rate its difficulty",
    paragraphText:
      "We use your rating to predict when you'll forget each question.",
    imgSrc: RateItsDifficultyImg,
  },
  {
    headerText: "Come back daily",
    paragraphText: "Zorbi only shows you the questions that you've forgotten.",
    imgSrc: ComeBackDailyImg,
  },
  // {
  //   headerText: "Ace your exams",
  //   paragraphText:
  //     'With Zorbi\'s science-backed framework for the "what", "when", and "how" to study',
  //   imgSrc: AceYourExamsImg,
  //   fillImg: true,
  // },
];

export const HowItWorks: React.FC = () => {
  return (
    <section className="px-4 pt-4 pb-16 mb-16 sm:px-0 bg-gray-50">
      <div className="container mx-auto text-center max-w-7xl">
        <h2 className="text-5xl font-extrabold leading-10 tracking-tight text-gray-900 sm:leading-none md:text-6xl">
          How it works
        </h2>
        <div className="grid grid-cols-1 gap-4 py-8 mt-10 md:grid-cols-2 xl:grid-cols-3 sm:px-8 xl:px-0">
          {timelineItems.map((t, index) => (
            <TimelineItem
              headerText={t.headerText}
              paragraphText={t.paragraphText}
              imgSrc={t.imgSrc}
              fillImg={true}
              showNext={index < timelineItems.length - 1}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

const TimelineItem: React.FC<TimelineItemProps & { showNext: boolean }> = ({
  headerText,
  paragraphText,
  imgSrc,
  showNext,
  fillImg,
}) => {
  return (
    <div className="relative flex flex-col items-center justify-center px-6 py-12 bg-gray-100 sm:rounded-xl">
      <div className="flex flex-col justify-center flex-grow">
        <img
          src={imgSrc}
          className={`rounded-xl object-contain max-h-40 mb-4 ${
            fillImg ? `w-full h-full` : ""
          }`}
        />
      </div>
      <div className="flex flex-col">
        <h4 className="text-lg font-semibold text-gray-800">{headerText}</h4>
        <p className="mt-1 text-base text-center text-gray-600">
          {paragraphText}
        </p>
      </div>
      {showNext && (
        <div className="absolute z-10 hidden p-2 text-purple-600 transform translate-x-1/2 bg-purple-200 rounded-full xl:block -right-2 top-1/2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
      )}
    </div>
  );
};
