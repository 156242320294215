/// <reference path="../../../types/media/media.d.ts" />

import React from "react";

import { NotionCollabFeature } from "../pages/NotionIntegrationPage";
import { MarketplaceFeature } from "./features/MarketplaceFeature";
import { ChromeFeature } from "./features/ChromeFeature";
import { CardEditorFeature } from "./features/CardEditorFeature";
import { SmartLearningFeature } from "./features/SmartLearningFeature";
import { ImageClozeFeature } from "./pricing/pricing";

export const Features: React.FC = () => {
  return (
    <>
      <div className="-mt-20 bg-blueGray-50">
        <ChromeFeature />
      </div>
      <div className="">
        <NotionCollabFeature
          showCaption={true}
          showLearnMore={true}
          mediaSide={"right"}
        />
      </div>
      <div className="bg-blueGray-50">
        <CardEditorFeature />
      </div>
      <div className="">
        <SmartLearningFeature />
      </div>
      <div className="bg-blueGray-50">
        <ImageClozeFeature />
      </div>
    </>
  );
};
