import React, { useRef } from "react";
import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import {
  LifetimeBanner,
  Navigation,
} from "../components/common/navigation/navigation";
import { Features } from "../components/sections/features";
import { StudentTestimonials } from "../components/sections/testimonials/TestimonialSections";
import FlavourFeature from "../components/sections/features/FlavourFeature.tsx";
import { Footer } from "../components/sections/footer";
import { GetStarted } from "../components/sections/getstarted";
import Header from "../components/sections/header";
import { HowItWorks } from "../components/sections/howitworks";
import { Partnerships } from "../components/sections/partnerships";
import { index } from "../utils/seoText";

const IndexPage = () => {
  const topRef = useRef(null);
  const emailFieldRef = useRef(null);

  return (
    <Layout>
      <SEO {...index} />
      <Navigation />
      <Header topRef={topRef} emailFieldRef={emailFieldRef} />
      {/* <FlavourFeature /> */}
      <StudentTestimonials />
      <HowItWorks />
      {/* <IntroHook/> */}
      <Features />
      {/* <Partnerships /> */}
      {/* <PricingSection /> */}
      {/* <Features /> */}
      {/* <FAQ /> */}
      <GetStarted />
      <Footer />
    </Layout>
  );
};

export default IndexPage;
