import React from "react";
import tw from "twin.macro";
import { AvailableInChromeBox } from "../common/AvailableInChromeBox";

interface IntegrationPageProps {
  headerImage: JSX.Element;
  title: JSX.Element;
  CTAText?: string;
  learnMoreLink?: string;
}

const HeroSecondaryButton = tw.a` underline cursor-pointer`;

export const IntegrationPage: React.FC<IntegrationPageProps> = ({
  title,
  children,
  headerImage,
  CTAText,
  learnMoreLink,
}) => {
  return (
    <>
      <section className="py-20 lg:py-32">
        <div className="flex flex-wrap">
          <div className="flex w-full px-4 lg:h-58">
            <div className="flex flex-col items-center pt-8 mx-auto my-auto text-center lg:pt-0">
              {headerImage}
              <h2 className="max-w-lg mt-4 mb-8 text-2xl font-bold leading-snug sm:text-4xl font-heading">
                {title}
              </h2>
              <div className=" mb-8">
                <p className="text-sm text-blueGray-500 leading-relaxed">
                  You can add the Zorbi Extension to all Chromium based browsers
                  including Chrome, Microsoft Edge, Opera and Brave
                </p>
                {/* <FeatureBody>
                Note-taking can be effective and efficient. We're helping you write better and effective notes.
              </FeatureBody> */}
              </div>
              <div className="flex flex-col space-y-4 text-center">
                <AvailableInChromeBox variant="default" />
                {learnMoreLink && (
                  <HeroSecondaryButton
                    href={learnMoreLink}
                    target="_blank"
                    rel="noreferrer"
                    className="text-purple-600"
                  >
                    How It Works (Guide)
                  </HeroSecondaryButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {children}
      <section className="pt-20 pb-24 bg-purple-600">
        <div className="max-w-xl px-4 mx-auto text-center">
          <h2 className="mt-3 text-lg font-bold text-white md:text-4xl font-heading">
            {CTAText || "What are you waiting for?"}
          </h2>
          <div className="mt-6">
            <AvailableInChromeBox variant="white" />
          </div>
          <div className="my-4">
            {learnMoreLink && (
              <HeroSecondaryButton
                href={learnMoreLink}
                target="_blank"
                rel="noreferrer"
                className="text-white font-semibold"
              >
                How It Works (Guide)
              </HeroSecondaryButton>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
