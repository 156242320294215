import React from "react";
import tw from "twin.macro";
import CollabAndCreate from "../../images/notion/collab.webm";
import CollabAndCreateMP4 from "../../images/notion/collab.mp4";
import CreateVideo from "../../images/notion/create.webm";
import CreateVideoMP4 from "../../images/notion/create.mp4";
import AlterVideo from "../../images/notion/alter.webm";
import AlterVideoMP4 from "../../images/notion/alter.mp4";
import { MediaFeature } from "../sections/features/MediaFeature";
import { useCanUseExtension, useDetectBrowser } from "../../utils";
import { IntegrationPage } from "./IntegrationPage";
import NotionLogo from "../../images/notion/notion.png";
import { LoomVideoEmbed } from "../common/LoomVideoEmbed";
import { StaticImage } from "gatsby-plugin-image";

export const HeroActionButton = tw.a`block px-8 py-4 mb-4 text-sm font-semibold leading-none text-center text-white bg-purple-600 sm:inline-block sm:mb-0 hover:bg-purple-700 rounded-DEFAULT`;

export const NotionCollabFeature: React.FC<{
  showCaption: boolean;
  showLearnMore: boolean;
  mediaSide: "left" | "right";
}> = ({ showCaption, showLearnMore, mediaSide }) => {
  return (
    <MediaFeature
      caption={showCaption && "📝 Write notes, but never review them?"}
      header={
        <>
          <span className="text-purple-600">Collaborate on flashcards</span>
          <br />
          while writing notes
        </>
      }
      body={
        <>
          With Zorbi, you can work with your friends to create notes in Notion.
          Our integration will turn them into ready-made flashcards on your
          Zorbi account which you can share with anyone.
          {showLearnMore && (
            <>
              <br />
              <br />
              <HeroActionButton href={"/make-flashcards-in-notion"}>
                Add to Notion
              </HeroActionButton>
            </>
          )}
        </>
      }
      media={
        <video
          autoPlay
          muted
          loop
          playsInline
          preload="auto"
          className=" xl:h-128 lg:h-80 rounded-DEFAULT"
        >
          <source src={CollabAndCreate} type="video/webm" />
          <source src={CollabAndCreateMP4} type="video/mp4" />
        </video>
      }
      mediaSide={mediaSide}
      disableMediaShadow={true}
    />
  );
};

const NotionEditFeature: React.FC<{}> = (props) => {
  return (
    <MediaFeature
      header={
        <>
          Edit and review flashcards from
          <br />
          <span className="text-purple-600">any device, wherever you are</span>
          <br />
        </>
      }
      body={
        <>
          Once you've synced a Notion page with the Zorbi extension, we'll keep
          your deck updated so you're always studying from the latest copy of
          your notes.
          <br />
          <br />
          That means you (or your friends) can add, edit, and delete flashcards
          from the Notion mobile or desktop app and they'll auto-update the next
          time you open Zorbi.
        </>
      }
      media={
        <video
          autoPlay
          muted
          loop
          playsInline
          preload="auto"
          className="lg:h-128 rounded-DEFAULT"
        >
          <source src={AlterVideo} type="video/webm" />
          <source src={AlterVideoMP4} type="video/mp4" />
        </video>
      }
      mediaSide={"left"}
    />
  );
};

const NotionCreateFeature: React.FC<{}> = (props) => {
  return (
    <MediaFeature
      header={
        <>
          Create and study flashcards
          <br />
          <span className="text-purple-600">from your Notion notes</span>
          <br />
        </>
      }
      body={
        <>
          Summarizing lectures or studying for a test? Find yourself constantly
          skimming notes but not retaining anything?
          <br />
          <br />
          Our Notion integration will fundamentally change the way you write
          notes. We've made it ridiculously easy for you to create flashcards
          straight from Notion.
        </>
      }
      media={
        <video
          autoPlay
          muted
          loop
          playsInline
          preload="auto"
          className="lg:h-128 rounded-DEFAULT"
        >
          <source src={CreateVideo} type="video/webm" />
          <source src={CreateVideoMP4} type="video/mp4" />
        </video>
      }
      mediaSide={"left"}
    />
  );
};

export const NotionIntegrationPage: React.FC = () => {
  const canUseExtension = useCanUseExtension();
  const browser = useDetectBrowser();

  return (
    <IntegrationPage
      title={<span>Create flashcards while writing notes in Notion</span>}
      headerImage={
        <StaticImage
          src={"../../images/notion/notion.png"}
          className="w-20 h-20 mb-4"
          placeholder="blurred"
          alt="Notion"
          width={20}
        />
      }
      learnMoreLink={
        "https://zorbiapp.notion.site/Making-Flashcards-In-Notion-98e69a6d1f314a6e9cd7f8d7896a0d2a"
      }
    >
      <section>
        <div className="bg-blueGray-50">
          <NotionCreateFeature />
        </div>
        <div>
          <NotionCollabFeature
            showCaption={false}
            showLearnMore={false}
            mediaSide="right"
          />
        </div>
        <div className="bg-blueGray-50">
          <NotionEditFeature />
        </div>
      </section>
    </IntegrationPage>
  );
};
